import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import TextField from '@material-ui/core/TextField';

import './skip_stop_styles.css'
import { Ouroboro } from 'react-spinners-css';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import TranslateUI from '../../components/Language/Translate.jsx';
import BackButton from '../../components/backbutton/backbutton.jsx'
import Resizer from "react-image-file-resizer";
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default class SkipStop extends Component {
    constructor(props) {
        super(props)

        this.state = {
            skipReason: 'other',
            route: {},
            reasonText: '',
            token: '',
            language: '',
            stateObject: {},
            isLoaded: false,
            photoUri: "",
            cameraActive: false,
            addPhotoMode: false,
            galleryActive: false,
            photoCentralScreen: false,
            dataSending: false,
            routeInactiveAlert: false,
            unresolvedStopsAlert: false,
            photoFinished: false,
        }
    }

    async componentDidMount() {
        this.mounted = true;
        this.setState({
            language: localStorage.getItem('language'),
            token: localStorage.getItem('token')
        })
        if (this.props.location.stateObject !== undefined) {
            this.setState({
                stateObject: this.props.location.stateObject,
                routeActive: this.props.location.stateObject.context.selectedRoute.task.status,
                finish: !this.props.location.stateObject.context.selectedRoute.stops.some(item => item.task_status === null),
                routeActive: this.props.route.task.status !== null ? true : false,
                allStopsComplete: this.props.location.stateObject.context.selectedRoute.stops.some(item => item.task_status !== null),
                finishPresent: this.props.location.stateObject.context.selectedRoute.transport.return_address ? true : false
            }, () => {this.setState({ isLoaded: true }) })
        } else {
            await this.getStopItem();

        }
        let localPhoto = localStorage.getItem('photo_uri');
        if (localPhoto !== null) {
            this.setState({ photoUri: localPhoto, photoFinished: true })
        }

    }

    static getDerivedStateFromProps(props, state) {
        if (props.route.task !== undefined) {
            if (props.route.task.status !== undefined) {
                if (props.route !== state.route) {
                    return { 
                        route: props.route,
                        routeActive: props.route.task.status !== null ? true : false
                    }
                }
            }
        }
        
        return null;
    }

    // componentDidUpdate() {
    //     //console.log(document.getElementByClassName("MuiPaper-root"));
    //     if (document.getElementById("input-camera") !== null) {
    //         document.getElementById("input-camera").click();
    //     }
    //     //document.getElementById("navigation-tabs").style.display = "none";

    // }

    handleChange = (event) => {
        if (this.mounted) {
            this.setState({
                skipReason: event.target.value
            })
        }
    }

    handleInput = (input) => {
        if (this.mounted) {
            this.setState({
                reasonText: input.target.value
            })
        }
    }

    endpoint() {
        if (window.location.host === 'dispatch.routistic.com') {
            return 'https://routistic.com/api/v1/';
        }
        return 'https://dev.routistic.com/api/v1/';
    }

    skipStop = async () => {
        if (this.state.routeActive === null) {
            this.setState({ routeInactiveAlert: true })
        } else {
            if (this.state.skipReason !== '') {
                this.setState({ dataSending: true })
                await fetch(`${this.endpoint()}tasks/view/${this.state.stateObject.routeId}/status/${this.props.location.stateObject.object.id}`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        "Content-Type": 'application/json',
                        Authorization: 'Bearer ' + this.state.stateObject.token,
                    },
                    body: JSON.stringify({
                        "task_status": "problem",
                        "comment": this.state.reasonText,
                        "reason": this.state.skipReason,
                        "photo": this.state.photoUri
                    })
                }).then(response => response.json()
                ).then(async () => {
                    let remainingStops = this.state.route.stops.filter(route => route.task_status === null);
                    if (remainingStops.length === 1) {
                        if (remainingStops[0].id === this.state.stateObject.object.id && !this.state.finishPresent) {
                            await this.changeRouteStatus();
                        }
                    }
                    this.props.update(this.state.stateObject.routeId);
                    this.setState({ dataSending: false })
                    localStorage.removeItem('photo_uri');
                    this.props.history.goBack();
                })
            } else {
                return;
            }
        }
    }

    changeRouteStatus = async () => {
        await fetch(`${this.endpoint()}tasks/view/${this.state.stateObject.routeId}/status`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
            },
            body: JSON.stringify({
                "status": "finished",
            })
        }).then(response => response.json()
        ).then((response) => {
            if (response !== false) {
                this.props.update(this.state.stateObject.routeId);
            } else {
                console.log('ERROR!', response);
            }
        })
    }

    getDomain = () => {
        if (window.location.host.split(":")[0] === 'localhost') {
            return `http://${window.location.host}`
        } else {
            return `https://${window.location.host}`
        }
    }

    getStopItem = async () => {
        await fetch(this.endpoint() + "tasks/view/" + window.location.pathname.split("/")[3], {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
            }

        }).then(response => response.json()
        ).then((response) => {
            if (response.msg === "success") {
                let currentObj = response.stops.filter(stop => stop.id.toString() === window.location.pathname.split("/")[5])[0];
                let finish = !response.stops.some(item => item.task_status === null)
                if (currentObj !== undefined) {
                    this.setState({ routes: response.stops, finishPresent: response.transport.return_address ? true : false, stateObject: { object: currentObj, routeId: response.task.id }, finish: finish, isLoaded: true, routeActive: response.task.status, allStopsComplete: response.stops.some(item => item.task_status !== null) }, () => { localStorage.setItem('selectedRouteId', window.location.pathname.split("/")[3]) })
                } else {
                    window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
                }
            } else {
                window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
            }
        })

    }

    handleTakePhoto = (dataUri) => {
        this.setState({ photoUri: dataUri }, () => { localStorage.setItem('photo_uri', this.state.photoUri) });
    }

    handlePhotoBack = () => {
        if (this.state.cameraActive) {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        } else if (this.state.galleryActive) {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        } else {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        }
    }

    handleImageUpload = (type) => {
        let upload = ''
        if (type === 'gallery') {
            upload = document.querySelector('#photo').files[0];
        } else {
            upload = document.querySelector('#input-camera').files[0];
        }
        // const reader = new FileReader();
        // reader.readAsDataURL(upload);
        // let picture = ""
        // reader.onload = function () {
        //     picture = reader.result;
        //
        // };

        //New logic with resizer
        Resizer.imageFileResizer(
            upload,
            1024,
            1024,
            'JPEG',
            85,
            0,
            uri => {
                this.setState({ photoUri: uri, photoFinished: false }, () => { localStorage.setItem('photo_uri', this.state.photoUri) })
            },
            'base64'
        );
    }

    clearPhoto = () => {
        this.setState({ photoUri: "", photoFinished: false });
        if (this.state.galleryActive) {
            let image = document.querySelector('input[type=file]');
            image.value = '';
            localStorage.removeItem('photo_uri');
        }
    }


    render() {
        return (
            this.mounted ?
                this.state.isLoaded ?
                    this.state.dataSending ?
                        <div className="Loading-Icon">
                            <Ouroboro color="#3B4252" />
                        </div>
                        :
                        this.state.addPhotoMode ?
                            <div className='camera'>
                                <Paper className='goback unselectable' elevation={5} square={true}>
                                    <ArrowBackIosIcon onClick={() => { this.handlePhotoBack() }} />
                                    {TranslateUI.translate(this.state.language, "go_back")}
                                    {this.state.photoUri.length > 1 ? <div onClick={() => { this.clearPhoto() }}><i className="fas fa-eraser clearicon" /></div> : null}
                                </Paper>
                                {this.state.photoCentralScreen ?
                                    this.state.photoUri.length > 1 ?
                                        <div className="photocontainer">
                                            {TranslateUI.translate(this.state.language, "preview")}
                                            <img className="photopreview" src={this.state.photoUri}></img>
                                            <button className="upload-button" onClick={() => { this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false, photoFinished: true }) }}>{TranslateUI.translate(this.state.language, "finish")}</button>
                                        </div>
                                        :
                                        <div className="photo-icon-container">
                                            <input type="file" onChange={() => { this.handleImageUpload('gallery') }} className='photo-input-invisible' id="photo" name="photo" accept="image/png, image/jpeg, image/jpg" />
                                            <input type="file" accept="image/*" capture="camera" onChange={() => { this.handleImageUpload('photo') }} className='photo-input-invisible' id="input-camera" name="input-camera" />
                                            <label htmlFor="photo"><div className="photoModeIcon"><p className="photoModeIcon-text">{TranslateUI.translate(this.state.language, "gallery")}</p><PhotoSizeSelectActualOutlinedIcon className='photoModeIcon-single' fontSize="large" /> </div></label>

                                            <label htmlFor="input-camera"><div className="photoModeIcon"><p className="photoModeIcon-text">{TranslateUI.translate(this.state.language, "camera")}</p><CameraAltOutlinedIcon className='photoModeIcon-single' fontSize="large" /></div></label>
                                        </div>
                                    :
                                    null
                                }
                                {this.state.cameraActive ?
                                    this.state.photoUri.length < 1 ?
                                        <div>
                                            <div style={{ backgroundColor: '#fff', width: '100%', height: '25px' }}></div>
                                            <input type="file" accept="image/*" capture="camera" onChange={() => { this.handleImageUpload() }} className='photo-input-invisible' id="input-camera" name="input-camera" />
                                        </div>
                                        :
                                        <div className="photocontainer">
                                            {TranslateUI.translate(this.state.language, "preview")}
                                            <img className='photopreview' src={this.state.photoUri} />
                                            <button className="upload-button" onClick={() => { this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false, photoFinished: true }) }}>{TranslateUI.translate(this.state.language, "finish")}</button>

                                        </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            <div className='Skipstop-screen'>
                                <Paper className='Skipstop-header-container unselectable' elevation={5} square={true}>
                                    <div className='Backbutton-container'>
                                        <BackButton />
                                    </div>
                                    <span className='Skipstop-header-text'>{TranslateUI.translate(this.state.language, "skip_this_stop")} '{this.state.stateObject.object.name}'</span>
                                </Paper>
                                <Collapse in={this.state.routeInactiveAlert} classes={{
                                    container: 'stopcard-alert'
                                }}>
                                    <Alert severity='error'
                                        action={
                                            <IconButton
                                                aria-label="close"
                                                color="inherit"
                                                size="small"
                                                onClick={() => {
                                                    this.setState({ routeInactiveAlert: false });
                                                }}
                                            >
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        }
                                    >
                                        {TranslateUI.translate(this.state.language, "alert_route_not_started")}
                                    </Alert>
                                </Collapse>
                                <div className='Skipstop-content-container'>
                                    <div onClick={() => { this.setState({ addPhotoMode: true, photoCentralScreen: true }) }} className="Completestop-button">{TranslateUI.translate(this.state.language, "add_photo")} {this.state.photoUri.length > 1 ? <DoneIcon className='checkmark' /> : null}</div>
                                    <div className='Skipstop-detail-container'>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{TranslateUI.translate(this.state.language, "choose_a_reason")}:</FormLabel>
                                            <RadioGroup aria-label="reason" name="reason" value={this.state.skipReason} onChange={(event) => this.handleChange(event)}>
                                                <FormControlLabel value="nobody_home" control={<Radio />} label={TranslateUI.translate(this.state.language, "nobody_home")} />
                                                <FormControlLabel value="wrong_address" control={<Radio />} label={TranslateUI.translate(this.state.language, "wrong_address")} />
                                                <FormControlLabel value="other" control={<Radio />} label={TranslateUI.translate(this.state.language, "other")} />
                                            </RadioGroup>
                                        </FormControl>
                                        <div>
                                            <h3>{TranslateUI.translate(this.state.language, "why_are_you_skipping")}</h3>
                                            <TextField
                                                id='standard-multiline-flexible'
                                                label='Enter here...'
                                                multiline
                                                fullWidth
                                                value={this.state.reasonText}
                                                onChange={(input) => this.handleInput(input)}
                                            />
                                        </div>
                                        <div className='Skipstop-button-container unselectable' onClick={() => this.skipStop()}>
                                            <span>{TranslateUI.translate(this.state.language, "skip_this_stop")}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    :
                    <div className="Loading-Icon">
                        <Ouroboro color="#3B4252" />
                    </div>
                :
                <div className="Loading-Icon">
                    <Ouroboro color="#3B4252" />
                </div>
        );
    }
}

