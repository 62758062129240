import React, { Component } from 'react';
import './mapcard_styles.css'

import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import red from '@material-ui/core/colors/red';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DirectionsIcon from '@material-ui/icons/Directions';
import ClickableContainer from '../clickable_container/clickable_container.jsx';
import TranslateUI from '../Language/Translate.jsx';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";

export default class MapCard extends Component {
    constructor(props) {
        super(props)

        this.state = {
            language: '',
            routeInactiveAlert: false,
            unresolvedStopsAlert: false,
            routeActive: null,
            allStopsComplete: false
        }
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            language: localStorage.getItem('language'),
            routeActive: this.props.context.selectedRoute.task.status,
            allStopsComplete: !this.props.context.selectedRoute.stops.some(item => item.task_status === null)
        })
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log('Will update?', nextProps.route.address !== this.state.route.address)
    //     console.log('Nextprops', nextProps.route, 'vs', this.state.route)
    //     return (nextProps.route.address !== this.state.route.address);
    // }

    endpoint() {
        if (window.location.host === 'dispatch.routistic.com') {
            return 'https://routistic.com/api/v1/';
        }
        return 'https://dev.routistic.com/api/v1/';
    }

    changeRouteStatus = async () => {
        if (this.props.context.selectedRoute.task.status === null) {
            this.setState({ routeInactiveAlert: true })
        } else if (!this.state.allStopsComplete) {
            this.setState({ unresolvedStopsAlert: true })
        } else {
            await fetch(`${this.endpoint()}tasks/view/${window.location.pathname.split("/")[3]}/status`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    Accept: 'application/json',
                    "Content-Type": 'application/json',
                    Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
                },
                body: JSON.stringify({
                    "status": "finished",
                })
            }).then(response => response.json()
            ).then((response) => {
                if (response !== false) {
                    this.props.update(window.location.pathname.split("/")[2]);
                } else {
                    console.log('ERROR!', response);
                }
            })
        }
    }


    render() {

        return (

            this.mounted ?
                <div className={this.props.routeActive && this.props.route.address ? 'Mapcard-box-inactive' : 'Mapcard-box-inactive-small'}>

                    <div className='Mapcard-box-active-left'>
                        <Collapse in={this.state.routeInactiveAlert} classes={{
                            container: 'stopcard-alert'
                        }}>
                            <Alert severity='error'
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            this.setState({ routeInactiveAlert: false });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {TranslateUI.translate(this.state.language, "alert_route_not_started")}
                            </Alert>
                        </Collapse>
                        <Collapse in={this.state.unresolvedStopsAlert} classes={{
                            container: 'stopcard-alert'
                        }}>
                            <Alert severity='error'
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            this.setState({ unresolvedStopsAlert: false });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {TranslateUI.translate(this.state.language, "alert_stops_not_resolved")}
                            </Alert>
                        </Collapse>
                        <div className='Mapcard-row-container'>
                            <span className='Mapcard-header-text'>{this.props.route.name}</span>
                            <div className='Mapcard-ETA-box'>
                                <span>{this.props.route.arrive_time}</span>
                            </div>
                        </div>
                        {this.props.route.address ?
                            <span className='Mapcard-address-text'>{this.props.route.address}</span>
                            :
                            null
                        }
                        
                        {this.props.route.arrive_time !== undefined && this.props.route.leave_time !== undefined && this.props.route.arrive_time !== this.props.route.leave_time?
                            <span className='Mapcard-address-text'>{this.props.route.arrive_time} - {this.props.route.leave_time}</span>
                            :
                            this.props.isLastItem || this.props.hasStarted && this.props.routeActive ?
                                null
                                :
                                <span className='Mapcard-address-text'>{TranslateUI.translate(this.state.language, "no_time_window")}</span>
                        }

                        {this.props.routeActive ?
                            <div className='Mapcard-action-holder'>
                                {this.props.route.address ?
                                    <div className='Mapcard-buttons-one'>
                                        <button className='Mapcard-moreinfo-container'>
                                            <InfoOutlinedIcon fontSize='small' color='primary' />
                                            <ClickableContainer path='more' css='Mapcard-moreinfo-text' pathId={this.props.id} routeId={this.props.context.selectedRoute.task.id} stateObject={this.props.route}>{TranslateUI.translate(this.state.language, "more_info")}</ClickableContainer>
                                        </button>
                                        <button className='Mapcard-directions-container'>
                                            <a href={"http://maps.google.com/?q=" + this.props.route.lat + "," + this.props.route.lng} target="_blank" className='link'>
                                                <DirectionsIcon fontSize='small' color='primary' />
                                                <span className='Mapcard-directions-text'>{TranslateUI.translate(this.state.language, "directions")}</span>
                                            </a>
                                        </button>
                                    </div>
                                    :
                                    null
                                }

                                <hr className='Mapcard-hr' />
                                <div className='Mapcard-buttons-two'>
                                    {this.props.isLastItem && this.props.route.task_status === 'driver'?
                                        <div className='Mapcard-button-arrive' onClick={() => {this.changeRouteStatus()}}>{TranslateUI.translate(this.state.language, "finish")}</div>
                                        :
                                        <ClickableContainer path='complete' css='Mapcard-button-arrive' {...this.props} stateObject={this.props.route} routeId={this.props.context.selectedRoute.task.id}>{TranslateUI.translate(this.state.language, "arrive")}</ClickableContainer>

                                    }
                                    {this.props.isLastItem && this.props.route.task_status === 'driver'?
                                        null
                                        :
                                        <ClickableContainer path='skip' css='Mapcard-button-skip' {...this.props} stateObject={this.props.route} routeId={this.props.context.selectedRoute.task.id}>{TranslateUI.translate(this.state.language, "skip")}</ClickableContainer>
                                    }
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>
                </div>
                :
                null

        );
    }
}
