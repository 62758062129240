import React, { Component } from 'react';
import './App.css';
import Navigation from './components/navigation.jsx'
import { Ouroboro } from 'react-spinners-css';

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      token: "",
      routes: [],
      selectedRoute: {},
      driver: {},
      language: 'en',
      routeId: "",
      defaultId: "",
      dataLoaded: false,
      invalidToken: null
    }
  }

  async componentDidMount() {
    this.mounted = true;
    if (window.location.href.split("/")[0] !== "not_found") {
      this.getLanguage();
      if (this.hasDriver()) {
      await this.getToken().then(async () => { await this.getRoutes() }).then(() => { this.getDriver() }).then(() => {
        this.setState({
          dataLoaded: true
        })
      })
      } else {
        this.setState({
          dataLoaded: true
        })
      }
    } else {
      this.setState({
        dataLoaded: true
      })
    }
    

  }

  getToken = async () => {
    let token = window.location.pathname.split("/");
    this.setState({ token: token[2] }, () => { localStorage.setItem('token', this.state.token) })
  }

  getRouteId = () => {
    let route = window.location.pathname.split("/");
    this.setState({ routeId: route[3] }, () => { localStorage.setItem('routeId', this.state.routeId) })
  }

  getLanguage = () => {
    let browserLang = navigator.language || navigator.userLanguage;
    let userLang = browserLang.substring(0, 2);
    switch (userLang) {
      case 'en':
        this.setState({ language: 'en' });
        localStorage.setItem('language', userLang);
        break;
      case 'lv':
        this.setState({ language: 'lv' });
        localStorage.setItem('language', userLang);
        break;
      default:
        this.setState({ language: 'en' });
        localStorage.setItem('language', 'en');
        break;
    }
  }

  componentWillUnmount() {
  }

  getRoutes = async () => {
    await fetch(this.endpoint() + "tasks/list", {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.state.token,
      }

    }).then(response => response.json()
    ).then(async (response) => {
      if (response.status !== false) {

        let storedRouteId = localStorage.getItem('selectedRouteId')
        this.setState({ routes: response.tasks, defaultId: response.tasks[0].id, invalidToken: false }, () => { this.fetchRoute(window.location.pathname.split("/")[3]) })

      } 
      else if (response.msg === "Unauthorized"){
        this.setState({invalidToken: true})
      }
    })
  }

  getDriver = async () => {
    await fetch(this.endpoint() + "driver/info", {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.state.token,
      }

    }).then(response => response.json()
    ).then(async (response) => {
      if (response.status !== false) {
        this.setState({ driver: response.driver })
      } else {
      }
    })
  }

  fetchRoute = async (id) => {
    let useId = id
    if (useId === null || useId === undefined || useId === "") {
      useId = this.state.routes[0].id
    } else {
      await fetch(this.endpoint() + "tasks/view/" + useId, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.state.token,
        }

      }).then(response => response.json()
      ).then((response) => {
        if (response.status !== false) {
          this.setState({ selectedRoute: { task: response.task, stops: response.stops.map((item, index) => index === 0 ? { ...item, focus: true } : { ...item, focus: false }), transport: response.transport }, routeId: response.task.id }, () => { localStorage.setItem('selectedRouteId', id) })
        } else {
          if (response.msg === "Not found") {
            this.fetchRoute(this.state.routes[0].id)
          }
        }
      })
    }
  }

  setRoute = (route) => {
    this.setState({ selectedRoute: route })
  }

  passDefaultId = () => {
    return this.state.defaultId
  }

  isInteger = value => {
    if (value === "" || value === undefined) {
      return false;
    } else {
      return parseInt(value) == value;
    }
  }

  endpoint() {
    if (window.location.host === 'dispatch.routistic.com') {
      return 'https://routistic.com/api/v1/';
    }
    return 'https://dev.routistic.com/api/v1/';
  }

  routeExists = (path) => {
    switch (path) {
      case "route":
        return null;
      case "map":
        return null;
      case "more":
        return true;
      case "skip":
        return true;
      case "complete":
        return true;
      
      default:
        return false;
    }
  }

  hasDriver = () => {
    if (window.location.pathname.split("/")[2] !== "" && window.location.pathname.split("/")[2] !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  getDomain = () => {
    if (window.location.host.split(":")[0] === 'localhost') {
      return `http://${window.location.host}`
    } else {
      return `https://${window.location.host}`
    }
  }

  render() {
    if (this.state.dataLoaded) {
      if (!this.hasDriver() && window.location.pathname.split("/")[1] !== "not_found" || this.state.invalidToken && window.location.pathname.split("/")[1] !== "not_found"){
        window.location.href = this.getDomain() + "/not_found/";
     } else if (this.hasDriver() && window.location.pathname.split("/")[3] === "" || this.hasDriver() && !this.isInteger(window.location.pathname.split("/")[3])) {
        
          // https://dispatch.routistic.com/driver/
          window.location.href = this.getDomain() + "/driver/" + this.state.token + "/" + this.state.defaultId + "/"
          //window.location.href = this.getDomain() + "/driver/" + this.state.token + "/" + this.state.defaultId + "/"
        
      } else if (this.hasDriver() && 
      window.location.pathname.split("/")[3] !== "" && 
      this.isInteger(window.location.pathname.split("/")[3]) && 
      window.location.pathname.split("/")[4] !== "map" && 
      window.location.pathname.split("/")[4] !=="routes" && 
      !this.routeExists(window.location.pathname.split("/")[4]) && 
      window.location.pathname.split("/")[4] !== undefined &&
      window.location.pathname.split("/")[4] !== ""
      ) {
        
          window.location.href = this.getDomain() + "/driver/" + this.state.token + "/" + this.state.defaultId + "/"
        
      } else if (this.hasDriver() && this.routeExists(window.location.pathname.split("/")[4]) && !this.isInteger(window.location.pathname.split("/")[5])) {
        
          window.location.href = this.getDomain() + "/driver/" + this.state.token + "/" + this.state.defaultId + "/"
        
      } else {
        return (
          <Navigation className='Screen' context={this.state} defaultId={this.state.defaultId} changeRoute={this.fetchRoute} />
        )
      }
    } else {
      return (
        <div className="Loading-Icon">
          <Ouroboro color="#3B4252" />
        </div>
      )
    }

    // return (

    //   this.mounted ?
    //     this.state.dataLoaded ?
    //        ?

    //         :

    //       :
    //       <div className="Loading-Icon">
    //         <Ouroboro color="#3B4252" />
    //       </div>
    //     :
    //     <div className="Loading-Icon">
    //       <Ouroboro color="#3B4252" />
    //     </div>
    // );
  }
}

export default App;
