import React, { Component } from 'react';
import './stopcard_styles.css'
import ClickableContainer from '../../clickable_container/clickable_container.jsx'

import red from '@material-ui/core/colors/red';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DirectionsIcon from '@material-ui/icons/Directions';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TranslateUI from '../../Language/Translate.jsx';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

export default class StopCardFocused extends Component {
    constructor(props) {
        super(props);

        this.state = {
            route: {},
            language: '',
            routeInactiveAlert: false,
            unresolvedStopsAlert: false,
            routeActive: null,
            allStopsComplete: false
        }

    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            route: this.props.route,
            language: localStorage.getItem('language'),
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props !== state && props.context !== undefined) {
            return {
                routeActive: props.context.selectedRoute.task.status,
                allStopsComplete: !props.context.selectedRoute.stops.some(item => item.task_status === null)
            }
        }
        return null;
    }

    endpoint() {
        if (window.location.host === 'dispatch.routistic.com') {
            return 'https://routistic.com/api/v1/';
        }
        return 'https://dev.routistic.com/api/v1/';
    }

    changeRouteStatus = async () => {
        if (this.state.routeActive === null) {
            this.setState({ routeInactiveAlert: true })
        } else if (!this.state.allStopsComplete) {
            this.setState({ unresolvedStopsAlert: true })
        } else {
        await fetch(`${this.endpoint()}tasks/view/${window.location.pathname.split("/")[3]}/status`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
            },
            body: JSON.stringify({
                "status": "finished",
            })
        }).then(response => response.json()
        ).then((response) => {
            if (response !== false) {
                this.props.update(window.location.pathname.split("/")[3]);
            } else {
                console.log('ERROR!', response);
            }
        })
        }
    }


    render() {

        return (
            this.mounted ?
                <div className='Stopcard-box-active' id='focused'>
                    <div className="Stopcard-timeline-box">
                        <div className='Stopcard-ETA-box'>
                            <span>{this.props.route.arrive_time}</span>
                        </div>
                        <span className={this.props.isLastLine ? 'Stopcard-line-invisible' : 'Stopcard-line-active'}></span>
                    </div>
                    <div className='Stopcard-box-active-left'>
                        <Collapse in={this.state.routeInactiveAlert} classes={{
                            container: 'stopcard-alert'
                        }}>
                            <Alert severity='error'
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            this.setState({ routeInactiveAlert: false });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {TranslateUI.translate(this.state.language, "alert_route_not_started")}
                            </Alert>
                        </Collapse >
                        <Collapse in={this.state.unresolvedStopsAlert} classes={{
                            container: 'stopcard-alert'
                        }}>
                            <Alert severity='error'
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            this.setState({ unresolvedStopsAlert: false });
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {TranslateUI.translate(this.state.language, "alert_stops_not_resolved")}
                            </Alert>
                        </Collapse>
                        <div className='Stopcard-row-container'>
                            <span className='Stopcard-header-text'>{this.props.route.name}</span>
                        </div>
                        {
                            this.props.route.address ?
                                <span className='Stopcard-address-text'>{this.props.route.address}</span>
                                :
                                null
                        }

                        {this.props.route.reg_nr === undefined || this.props.route.reg_nr === '' || this.props.route.reg_nr === null ?
                            null
                            :
                            <span className='Stopcard-address-text'>#{this.props.route.reg_nr}</span>
                        }

                        {this.props.route.arrive_time !== undefined && this.props.route.leave_time !== undefined && this.props.route.arrive_time !== this.props.route.leave_time?
                            <span className='Stopcard-address-text'>{this.props.route.arrive_time} - {this.props.route.leave_time}</span>
                            :
                            this.props.isLastItem || this.props.hasStarted && this.props.routeActive ?
                                null
                                :
                                // <span className='Stopcard-address-text'>{TranslateUI.translate(this.state.language, "no_time_window")}</span>
                                null
                        }
                        {this.props.hasStarted && this.props.routeActive ?
                            <div>
                                {this.props.route.address ?
                                    <div className='Stopcard-buttons-one'>
                                        <ClickableContainer css='Stopcard-moreinfo-container' path='more' routeId={this.props.context.selectedRoute.task.id} stateObject={this.props.route} >
                                            <InfoOutlinedIcon fontSize='small' color='primary' />
                                            <span className='Stopcard-moreinfo-text'>{TranslateUI.translate(this.state.language, "more_info")}</span>
                                        </ClickableContainer>
                                        <button className='Stopcard-directions-container'>
                                            <a href={"http://maps.google.com/?q=" + this.props.route.lat + "," + this.props.route.lng} target="_blank" className='link'>
                                                <DirectionsIcon fontSize='small' color='primary' />
                                                <span className='Stopcard-directions-text'>{TranslateUI.translate(this.state.language, "directions")}</span>
                                            </a>
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                                <hr className='Stopcard-hr' />
                                {this.props.isLastItem ?
                                    <div className='Stopcard-buttons-two'>
                                        <div className='Stopcard-button-arrive unselectable' onClick={() => { this.changeRouteStatus() }}>{TranslateUI.translate(this.state.language, "finish")}</div>
                                    </div>
                                    :
                                    <div className='Stopcard-buttons-two'>
                                        <ClickableContainer path='complete'
                                            stateObject={this.props.route}
                                            {...this.props}
                                            routeId={this.props.context.selectedRoute.task.id}
                                            css='Stopcard-button-arrive'
                                            finish={this.props.isLastLine}
                                        >
                                            {TranslateUI.translate(this.state.language, "arrive")}
                                        </ClickableContainer>
                                        <ClickableContainer path='skip'
                                            stateObject={this.props.route}
                                            {...this.props}
                                            routeId={this.props.context.selectedRoute.task.id}
                                            css='Stopcard-button-skip'
                                            finish={this.props.isLastLine}
                                        >
                                            {TranslateUI.translate(this.state.language, "skip")}
                                        </ClickableContainer>
                                    </div>
                                }
                            </div>
                            :
                            <div className='Stopcard-buttons-two'>
                                <button className='Stopcard-button-arrive unselectable' onClick={() => this.props.changeStatus("started")}>{TranslateUI.translate(this.state.language, "start")}</button>
                            </div>
                        }
                    </div>
                </div>
                :
                null
        );
    };
}
