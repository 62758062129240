import React, { Component } from 'react';

import './stoplist_styles.css'

import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Ouroboro } from 'react-spinners-css';

import StopCard from '../../components/stoplist_cards/stopcard/stopcard.jsx'
import StopCardFinished from '../../components/stoplist_cards/stopcard/stopcard_finished.jsx';
import StopCardFocused from '../../components/stoplist_cards/stopcard/stopcard_focused.jsx';
import TranslateUI from '../../components/Language/Translate.jsx';


export default class StopList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recievedProps: false,
            route: [],
            hasFocus: false
        }
    }

    componentDidMount() {
        this.mounted = true;

        setTimeout(() => {
            var item = document.getElementById("focused")
            if (item) {
                
                item.scrollIntoView({ block: "center", behavior: "smooth" });
            }
        }, 100);

        if (this.props.context.selectedRoute.stops === undefined) {
            return;
        }
        this.setState({
            hasStarted: this.props.context.selectedRoute.task.status === null ? true : false,
            isActive: this.props.context.selectedRoute.task.status !== 'finished' ? true : false,
            route: this.props.context.selectedRoute,
            language: localStorage.getItem('language')
        });

    }

    componentDidUpdate() {

        setTimeout(() => {
            var item = document.getElementById("focused")
            if (item) {
                item.scrollIntoView({ block: "center", behavior: "smooth" });
            }
        }, 200);
    }



    endpoint() {
        if (window.location.host === 'dispatch.routistic.com') {
            return 'https://routistic.com/api/v1/';
        }
        return 'https://dev.routistic.com/api/v1/';
    }

    changeRouteStatus = async (status) => {
        await fetch(`${this.endpoint()}tasks/view/${this.props.context.selectedRoute.task.id}/status`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                Authorization: 'Bearer ' + this.props.context.token,
            },
            body: JSON.stringify({
                "status": status
            })
        }).then(response => response.json()
        ).then((response) => {
            if (response !== false) {
                if (status === 'started') {
                    this.setState({ isActive: true, hasStarted: true }, () => { this.props.change(this.props.context.selectedRoute.task.id) })
                } else if (status === 'finished') {
                    this.setState({ isActive: false }, () => { this.props.change(this.props.context.selectedRoute.task.id) })
                }
            } else {
                console.log('ERROR!', response);
            }
        })
    }

    static getDerivedStateFromProps(props, state) {
        if (props.context.selectedRoute.task !== undefined) {
            return {
                hasStarted: props.context.selectedRoute.task.status === 'started' ? true : false,
                isActive: props.context.selectedRoute.task.status !== 'finished' ? true : false,
                route: props.context.selectedRoute,
            }
        } else {
            return null;
        }
    }

    


    componentWillUnmount() {

    }

    render() {


        return (
            this.props.context.selectedRoute.stops !== undefined ?
                this.mounted ?
                    this.props.context.selectedRoute.stops.length === 0 ?
                        <div className="no-valid-stops">No valid stops</div>
                        :
                        <div>
                        <div className='Stoplist-header-container'>
                                <div className='Stoplist-leftside-header unselectable'>
                                    <LocationOnIcon fontSize='small' />
                                    <span className='Stoplist-header-text'>{this.state.route.stops.length} {TranslateUI.translate(this.state.language, "stops")}</span>
                                </div>
                                <div style={{ marginRight: 35 }}>
                                    <span className='Stoplist-header-text'>{this.state.route.task.name}</span>
                                </div>
                            </div>
                        <div className='Stoplist-screen'>
                            

                            <div className={this.state.isActive ? 'Stoplist-inner-active-container' : 'Stoplist-inner-container'}>
                                {this.state.hasStarted || !this.state.isActive ?
                                    <StopCardFinished route={{
                                        name: this.state.route.transport.name,
                                        address: this.state.route.transport.base_address,
                                        id: 0,
                                        task_status: "done",
                                        lat: this.state.route.transport.return_lat,
                                        lng: this.state.route.transport.return_lng
                                    }}
                                    />
                                    :
                                    <StopCardFocused
                                        hasStarted={false}
                                        route={{
                                            name: this.state.route.transport.name,
                                            address: this.state.route.transport.base_address,
                                            arrive_time: this.state.route.transport.work_start,
                                            id: 0,
                                            lat: this.state.route.transport.return_lat,
                                            lng: this.state.route.transport.return_lng
                                        }}
                                        changeStatus={this.changeRouteStatus}
                                        update={this.props.change}
                                    />}

                                {this.state.isActive ?

                                    this.state.route.stops.map((item, index) => {
                                        if (item.task_status !== null) {

                                            return (

                                                <StopCardFinished
                                                    key={item.id}
                                                    id={item.id}
                                                    route={item}
                                                    isLastItem={false}
                                                    isLastLine={this.state.route.transport.return_address === '' ? index === this.state.route.stops.length-1 ? true : false : false}
                                                    {...this.props} />
                                            )
                                        } else if (index !== 0 && this.state.route.stops[index - 1].task_status !== null && this.state.hasStarted && !this.state.route.stops.some((item, indexTwo) => item.focused === true && indexTwo !== index) || index === 0 && this.state.route.stops.some(item => item.task_status === null) && this.state.hasStarted) {
                                            item.focused = true;
                                            return (
                                                <StopCardFocused
                                                    key={item.id}
                                                    route={item}
                                                    id={item.id}
                                                    isLastLine={this.state.route.transport.return_address === '' ? index === this.state.route.stops.length-1 ? true : false : null}
                                                    isLastItem={false}
                                                    routeActive={this.state.isActive}
                                                    hasStarted={this.state.hasStarted}
                                                    changeStatus={this.changeRouteStatus}
                                                    update={this.props.change}
                                                    {...this.props} />

                                            )

                                        } else {
                                            return (
                                                
                                                <StopCard
                                                    key={item.id}
                                                    route={item}
                                                    id={item.id}
                                                    isLastLine={this.state.route.transport.return_address === '' ? index === this.state.route.stops.length-1 ? true : false : false}
                                                    isLastItem={false}
                                                    routeActive={this.state.isActive}
                                                    update={this.props.change}
                                                    {...this.props} />
                                            )
                                        }
                                    })
                                    :
                                    this.state.route.stops.map((item, index) => {
                                        return (
                                            <StopCardFinished
                                                key={item.id}
                                                route={item}
                                                id={item.id}
                                                isLastLine={this.state.route.transport.return_address === '' ? index === this.state.route.stops.length-1 ? true : false : false}
                                                isLastItem={false}
                                                {...this.props} />
                                        )
                                    })
                                }
                                {this.state.route.transport.return_address === '' ?
                                    null
                                    :
                                    this.state.isActive ?
                                        this.state.route.stops.some(item => item.task_status === null) || !this.state.hasStarted ?
                                            <StopCard route={{
                                                name: this.state.route.transport.name,
                                                address: this.state.route.transport.return_address,
                                                arrive_time: this.state.route.transport.return_time,
                                                id: 1,
                                                task_status: "done",
                                                lat: this.state.route.transport.return_lat,
                                                lng: this.state.route.transport.return_lng
                                            }}
                                                isLastItem={true}
                                                isLastLine={true}
                                                routeActive={this.state.isActive}
                                                update={this.props.change}
                                                changeStatus={this.changeRouteStatus}
                                                {...this.props}
                                            />
                                            :
                                            <StopCardFocused
                                                hasStarted={true}
                                                route={{
                                                    name: this.state.route.transport.name,
                                                    address: this.state.route.transport.return_address,
                                                    arrive_time: this.state.route.transport.return_time,
                                                    id: 1,
                                                    lat: this.state.route.transport.return_lat,
                                                    lng: this.state.route.transport.return_lng
                                                }}
                                                changeStatus={this.changeRouteStatus}
                                                routeActive={true}
                                                isLastItem={true}
                                                isLastLine={true}
                                                update={this.props.change}
                                                {...this.props}
                                            />
                                        :
                                        <StopCardFinished route={{
                                            name: this.state.route.transport.name,
                                            address: this.state.route.transport.return_address,
                                            id: 1,
                                            task_status: "done",
                                        }}
                                            isLastItem={true}
                                            isLastLine={true}
                                            {...this.props}
                                        />
                                }

                            </div>

                        </div>
                        </div>
                    :
                    <div className="Loading-Icon">
                        <Ouroboro color="#3B4252" />
                    </div>
                :
                <div className="Loading-Icon">
                    <Ouroboro color="#3B4252" />
                </div>
        )
    }
}