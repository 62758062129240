import React, {Component} from 'react';

import './mapcard_styles.css'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import ClickableContainer from '../clickable_container/clickable_container.jsx';


//Container component for focused map cards
export default class MapCardFinished extends Component {
    constructor(props) {
        super(props);

        this.state = {
            route: {},
            language: ''
        }
        
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            status: this.props.route.task_status === "done" ? true : false,
            route: this.props.route,
            language: localStorage.getItem('language')
        })
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (nextProps.route.address !== this.state.route.address);
    }

    render() {

        return (
            this.mounted ? 
            <ClickableContainer path='more' css="Mapcard-box-finished" stateObject={this.props.route} routeId={this.props.id}>
                <div className='Mapcard-box-active-left'>
                    <div className='Mapcard-row-container'>
                        <span className='Mapcard-header-text-inactive'>{this.props.route.name}</span>
                    </div>
                        <span className='Mapcard-address-text-inactive'>{this.props.route.address}</span>
                        <button className='Mapcard-moreinfo-container'>
                                <InfoOutlinedIcon fontSize='small' className='Mapcard-moreinfo-icon'/>
                        </button>
                        
                    </div>
            </ClickableContainer>
            :
            null
            ); };
    }
