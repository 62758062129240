import React, { Component } from 'react';

class Language extends Component {

    constructor(props){
        super(props);
    }

    //Translate phrase
    translate = (lang="en", phrase) => {
        let Lang;
        let getMe = phrase;

        //Switch languages
        switch(lang){
            //English
            case "en":
                Lang = require('../../constants/languages/en.json');
                break;

            //Latvian
            case "lv":
                Lang = require('../../constants/languages/lv.json');
                break;

            //Russian
            case "ru":
                Lang = require('../../constants/languages/ru.json');
                break; 
        }

        for (let trans in Lang) {
            if(trans === phrase) {
                return Lang[trans];
            }
        }

    };
}

const TranslateUI = new Language();

export default TranslateUI;
