import React, { Component, useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Ouroboro } from 'react-spinners-css';

import './routes_styles.css';

import ClickableContainer from '../../components/clickable_container/clickable_container.jsx';
import TranslateUI from '../../components/Language/Translate.jsx';



export default class Routes extends Component {

  constructor(props) {
    super(props)

    this.state = {
      rowsPerPage: 10,
      page: 0,
      recievedProps: false,
      token: "",
      rows: [{}],
      routes: [{}],
      selectedRoute: {},
      language: '',
      columns: [
        {}
      ]
    }
  }


  componentDidMount() {
    this.mounted = true;
    this.setState({
      language: localStorage.getItem('language'),
      selectedRoute: this.props.context.selectedRoute,
      routes: this.props.context.routes,
      columns: [
        { id: 'routes', label: TranslateUI.translate(this.props.context.language, "routes"), minWidth: 50 },
        { id: 'stops', label: TranslateUI.translate(this.props.context.language, "stops"), minWidth: 10 },
        { id: 'shift', label: TranslateUI.translate(this.props.context.language, "shift"), minWidth: 50 },
      ]
    }, () => {this.setState({recievedProps: this.state.selectedRoute.task !== undefined})})
  }



  static getDerivedStateFromProps(props, state) {
    if (props.context !== undefined) {
      return {
        selectedRoute: props.context.selectedRoute,
        routes: props.context.routes,
        token: props.context.token,
        driver: props.context.driver,
        recievedProps: props.context.selectedRoute.task !== undefined
      }
    } else {
      return null;
    }
  }

  createData(routes, stops, distance, shift) {
    return { routes, stops, distance, shift };
  }


  render() {
    return (
      this.mounted ?

        this.state.recievedProps ?
          <div className="Routes-screen">
            <div className='Map-header-container unselectable'>
              <div className='Map-leftside-header'>
                <LocationOnIcon fontSize='small' />
                <span className='Stoplist-header-text'>{this.state.selectedRoute.task.stops_count} {TranslateUI.translate(this.state.language, "stops")}</span>
              </div>
              <div>
                <span className='Stoplist-header-text'>{this.state.selectedRoute.task.name}</span>
              </div>
            </div>
            <TableContainer >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {this.state.columns.map((column, index) => (
                      <TableCell
                        key={index}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor: "#3B4252", color: '#fff' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.routes.map((row, index) => {
                    return (
                        <ClickableContainer key={"click_#"+index} path='/' change={this.props.change} newRouteId={row.id} css='MuiTableRow-root MuiTableRow-hover'>
                          {this.state.columns.map((column, index) => {
                            const data = this.createData(row.name, row.stops_count, null, row.work_start);
                            const value = data[column.id]
                            return (

                              <TableCell key={index} align={column.align}>

                                {column.format && typeof (value) === 'number' ? column.format(value) : value}

                              </TableCell>

                            );
                          })}
                        </ClickableContainer>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          :
          <div className="Loading-Icon">
            <Ouroboro color="#3B4252" />
          </div>
        :
        <div className="Loading-Icon">
          <Ouroboro color="#3B4252" />
        </div>
    )
  }
}