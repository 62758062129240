import React, {Component} from 'react';
import './stopcard_styles.css'
import ClickableContainer from '../../clickable_container/clickable_container.jsx'


import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import red from '@material-ui/core/colors/red';
import { makeStyles, styled, withStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import TranslateUI from '../../Language/Translate.jsx';

//Container component for finshed route items
export default class StopCardFinished extends Component {
    constructor(props) {
        super(props);

        this.state = {
            //Status indicates if route item is completed or skiped
            status: false,
            route: {},
            language: ''
        }
        
    }

    componentDidMount() {
        this.mounted = true;
        this.setState({
            status: this.props.route.task_status === "done" ? true : false,
            route: this.props.route,
            language: localStorage.getItem('language')
        })
    }

    render() {
        //Icon style overrides
        const StyledCheckIcon = withStyles(() => ({
            root: {
              color: '#2c8dfb',
            },
          }))((props) => <CheckCircleIcon {...props} />);
        
          const StyledCancelIcon = withStyles(() => ({
            root: {
              color: '#f16466',
            },
          }))((props) => <CancelIcon {...props} />);

          const StyledInfoIcon = withStyles(() => ({
            root: {
              color: '#fff',
            },
          }))((props) => <InfoOutlinedIcon {...props} />);

        return (
            this.mounted ? 
            <ClickableContainer path='more' css="Stopcard-box-finished" stateObject={this.props.route} routeId={this.props.id}>
                <div className="Stopcard-timeline-box">
                    {this.state.status ?
                        <StyledCheckIcon className='Stopcard-icon' fontSize='large'/>
                    :
                        <StyledCancelIcon className='Stopcard-icon' fontSize='large'/>
                    }
                    <span className={this.props.isLastLine ? 'Stopcard-line-invisible' : 'Stopcard-line-inactive'}></span>
                </div>
                <div className='Stopcard-box-active-left'>
                    <div className='Stopcard-row-container'>
                        <span className='Stopcard-header-text-inactive'>{this.props.route.name}</span>
                    </div>
                        <span className='Stopcard-address-text-inactive'>{this.props.route.address}</span>
                        {this.props.route.reg_nr === undefined || this.props.route.reg_nr === '' || this.props.route.reg_nr === null?
                            null
                            :
                            <span className='Stopcard-address-text-inactive'>#{this.props.route.reg_nr}</span>
                        }
                        {/* <div>
                            <span className='Stopcard-address-text-inactive'>No Time Window</span>
                        </div> */}
                        
                        <button className='Stopcard-moreinfo-container'>
                                <StyledInfoIcon fontSize='small' />
                                <span className='Stopcard-moreinfo-text-inactive'>{TranslateUI.translate(this.state.language, "more_info")}</span>
                        </button>
                        
                    </div>
            </ClickableContainer>
            :
            null
            ); };
    }
