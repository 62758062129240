import React, { Component } from 'react';
import './complete_stop_styles.css'
import ReactDOM from 'react-dom';
import { Camera } from 'react-cam';

import BackButton from '../../components/backbutton/backbutton.jsx';
import TranslateUI from '../../components/Language/Translate.jsx';
import ClickableContainer from '../../components/clickable_container/clickable_container.jsx';

//import Camera, { FACING_MODES } from 'react-html5-camera-photo';
//import Camera from '../../components/CustomCamera/custom_camera.jsx'
import 'react-html5-camera-photo/build/css/index.css';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import SignatureCanvas from 'react-signature-canvas';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import { Ouroboro } from 'react-spinners-css';
import Resizer from 'react-image-file-resizer';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ConsoleView } from 'react-device-detect';

export default class CompleteStop extends Component {
    constructor(props) {
        super(props)


        this.sigCanvas = React.createRef();
        this.cam = React.createRef();



        this.state = {
            reasonText: '',
            language: '',
            stateObject: {},
            route: {},
            isLoaded: false,
            finish: false,
            cameraActive: false,
            signatureActive: false,
            photoUri: "",
            signatureUri: "",
            addPhotoMode: false,
            galleryActive: false,
            photoCentralScreen: false,
            dataSending: false,
            signatureFinished: false,
            routeInactiveAlert: false,
            unresolvedStopsAlert: false,
            photoFinished: false,
            finishPresent: false,
            routeActive: false
        }
    }

    async componentDidMount() {
        this.mounted = true;
        this.setState({
            language: localStorage.getItem('language'),
            token: localStorage.getItem('token'),

        })
        if (this.props.location.stateObject !== undefined) {
            this.setState({
                routes: this.props.location.stateObject.context.selectedRoute.stops,
                stateObject: this.props.location.stateObject,
                finish: !this.props.location.stateObject.context.selectedRoute.stops.some(item => item.task_status === null),
                routeActive: this.props.route.task.status !== null ? true : false,
                allStopsComplete: this.props.location.stateObject.context.selectedRoute.stops.some(item => item.task_status !== null),
                finishPresent: this.props.location.stateObject.context.selectedRoute.transport.return_address ? true : false
            }, () => {this.setState({ isLoaded: true }) })
        } else {
            await this.getStopItem();
        }
        let localSignature = localStorage.getItem('signature_uri');
        let localPhoto = localStorage.getItem('photo_uri');
        if (localPhoto !== null) {
            this.setState({ photoUri: localPhoto, photoFinished: true })
        }
        if (localSignature !== null) {
            this.setState({ signatureUri: localSignature, signatureFinished: true })
        }
    }

    componentWillUnmount() {
        //document.getElementById("navigation-tabs").style.display = "block";
    }

    componentDidUpdate() {
        //console.log(document.getElementByClassName("MuiPaper-root"));
        // if (document.getElementById("input-camera") !== null) {
        //     document.getElementById("input-camera").click();
        // }
        //document.getElementById("navigation-tabs").style.display = "none";

    }

    static getDerivedStateFromProps(props, state) {
        if (props.route.task !== undefined) {
            if (props.route.task.status !== undefined) {
                if (props.route !== state.route) {
                    return { 
                        route: props.route,
                        routeActive: props.route.task.status !== null ? true : false
                    }
                }
            }
        }
        return null;
    }

    getDomain = () => {
        if (window.location.host.split(":")[0] === 'localhost') {
            return `http://${window.location.host}`
        } else {
            return `https://${window.location.host}`
        }
    }


    endpoint() {
        if (window.location.host === 'dispatch.routistic.com') {
            return 'https://routistic.com/api/v1/';
        }
        return 'https://dev.routistic.com/api/v1/';
    }



    getStopItem = async () => {
        await fetch(this.endpoint() + "tasks/view/" + window.location.pathname.split("/")[3], {
            method: 'GET',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
            }

        }).then(response => response.json()
        ).then((response) => {
            if (response.msg === "success") {
                let currentObj = "";
                let finish = false;
                if (window.location.pathname.split("/")[5] === '0' || window.location.pathname.split("/")[5] === '1') {
                    currentObj = {
                        address: window.location.pathname.split("/")[5] === 0 ? response.transport.base_address : response.transport.return_address,
                        arrive_time: window.location.pathname.split("/")[5] === 0 ? response.transport.start_time : response.transport.return_time,
                        id: window.location.pathname.split("/")[5] === 0 ? 0 : 1,
                        lat: window.location.pathname.split("/")[5] === 0 ? response.transport.base_lat : response.transport.return_lat,
                        lng: window.location.pathname.split("/")[5] === 0 ? response.transport.base_lng : response.transport.return_lng,
                        name: response.transport.name,
                        phone: response.transport.driver_phone,
                    }
                } else {
                    let currentId = response.stops[response.stops.length - 1].id;
                    currentObj = response.stops.filter(stop => stop.id.toString() === window.location.pathname.split("/")[5])[0];
                    finish = !response.stops.some(item => item.task_status === null)
                }

                if (currentObj !== undefined) {
                    this.setState({ routes: response.stops, finishPresent: response.transport.return_address ? true : false, stateObject: { object: currentObj, routeId: response.task.id }, finish: finish, isLoaded: true, routeActive: response.task.status, allStopsComplete: response.stops.some(item => item.task_status !== null) }, () => { localStorage.setItem('selectedRouteId', window.location.pathname.split("/")[3]) })
                } else {
                    window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
                }

            } else {
                window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
            }
        })

    }

    handleInput = (input) => {
        if (this.mounted) {
            this.setState({
                reasonText: input.target.value
            })
        }
    }


    completeStop = async () => {
        let id = this.state.stateObject.routeId
        if (this.state.routeActive === null || this.state.routeActive === undefined || !this.state.routeActive) {
            this.setState({ routeInactiveAlert: true })
        } else {
            if (id === null || id === undefined) {
                let storage = localStorage.getItem('selectedRoute')
            } else {
                this.setState({ dataSending: true })
                await fetch(`${this.endpoint()}tasks/view/${id}/status/${this.state.stateObject.object.id}`, {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: 'application/json',
                        "Content-Type": 'application/json',
                        Authorization: 'Bearer ' + window.location.pathname.split("/")[2]
                    },
                    body: JSON.stringify({
                        "task_status": "done",
                        "comment": this.state.reasonText,
                        "signature": this.state.signatureUri,
                        "photo": this.state.photoUri

                    })
                }).then(response => response.json()
                ).then(async (response) => {
                    let remainingStops = this.state.route.stops.filter(route => route.task_status === null);
                    if (remainingStops.length === 1) {
                        if (remainingStops[0].id === this.state.stateObject.object.id && !this.state.finishPresent) {
                            await this.changeRouteStatus();
                        }
                    }
                    this.props.update(id);
                    this.setState({ dataSending: false })
                    localStorage.removeItem('photo_uri');
                    localStorage.removeItem('signature_uri');
                    this.props.history.goBack();

                })
            }
        }

    }

    changeRouteStatus = async () => {
        await fetch(`${this.endpoint()}tasks/view/${this.state.stateObject.routeId}/status`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                "Content-Type": 'application/json',
                Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
            },
            body: JSON.stringify({
                "status": "finished",
            })
        }).then(response => response.json()
        ).then((response) => {
            if (response !== false) {
                this.props.update(this.state.stateObject.routeId);
            } else {
                console.log('ERROR!', response);
            }
        })
    }

    handleTakePhoto = (dataUri) => {
        this.setState({ photoUri: dataUri }, () => { localStorage.setItem('photo_uri', this.state.photoUri) });
    }

    handleSignatureStroke = async () => {
        this.setState({ signatureUri: this.sigCanvas.toDataURL() }, () => { localStorage.setItem('signature_uri', this.state.signatureUri) });
    }



    clearCanvas = () => {
        this.sigCanvas.clear()
        this.setState({ signatureUri: "", signatureFinished: false });
        localStorage.removeItem('signature_uri')
    }

    handlePhotoBack = () => {
        if (this.state.cameraActive) {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        } else if (this.state.galleryActive) {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        } else {
            this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false });
            if (!this.state.photoFinished) {
                this.setState({ photoUri: '' });
                localStorage.removeItem('photo_uri');
            }
        }
    }

    handleImageUpload = (type) => {
        let upload = ''
        if (type === 'gallery') {
            upload = document.querySelector('#photo').files[0];
        } else {
            upload = document.querySelector('#input-camera').files[0];
        }
        //New logic with resizer
        Resizer.imageFileResizer(
            upload,
            1024,
            1024,
            'JPEG',
            85,
            0,
            uri => {
                this.setState({ photoUri: uri, photoFinished: false }, () => { localStorage.setItem('photo_uri', this.state.photoUri) })
            },
            'base64'
        );

        // const reader = new FileReader();
        // reader.readAsDataURL(upload);
        // let picture = ""
        // reader.onload = function () {
        //     picture = reader.result;
        //
        // };

        // setTimeout(() => {
        //     this.setState({ photoUri: picture }, () => { localStorage.setItem('photo_uri', this.state.photoUri) })
        // }, 500)

    }

    clearPhoto = () => {
        this.setState({ photoUri: "", photoFinished: false });
        if (this.state.galleryActive) {
            let image = document.querySelector('input[type=file]');
            image.value = '';
            localStorage.removeItem('photo_uri')
        }
    }



    render() {


        return (
            this.mounted ?
                this.state.isLoaded ?
                    this.state.dataSending ?

                        <div className="Loading-Icon">
                            <Ouroboro color="#3B4252" />
                        </div>
                        :

                        this.state.addPhotoMode ?
                            <div className='camera'>
                                <Paper className='goback unselectable' elevation={5} square={true}>
                                    <div className="goback-button" onClick={() => { this.handlePhotoBack() }} >
                                        <ArrowBackIosIcon />
                                        {TranslateUI.translate(this.state.language, "go_back")}
                                    </div>
                                    {this.state.photoUri.length > 1 ? <div onClick={() => { this.clearPhoto() }}><i className="fas fa-eraser clearicon" /></div> : null}
                                </Paper>
                                {this.state.photoCentralScreen ?
                                    this.state.photoUri.length > 1 ?
                                        <div className="photocontainer">
                                            {TranslateUI.translate(this.state.language, "preview")}
                                            <img className="photopreview" src={this.state.photoUri}></img>
                                            <button className="upload-button" onClick={() => { this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false, photoFinished: true }) }}>{TranslateUI.translate(this.state.language, "finish")}</button>
                                        </div>
                                        :
                                        <div className="photo-icon-container">
                                            <input type="file" onChange={() => { this.handleImageUpload('gallery') }} className='photo-input-invisible' id="photo" name="photo" accept="image/png, image/jpeg, image/jpg" />
                                            <input type="file" accept="image/*" capture="camera" onChange={() => { this.handleImageUpload('photo') }} className='photo-input-invisible' id="input-camera" name="input-camera" />
                                            <label htmlFor="photo"><div className="photoModeIcon"><p className="photoModeIcon-text">{TranslateUI.translate(this.state.language, "gallery")}</p><PhotoSizeSelectActualOutlinedIcon className='photoModeIcon-single' fontSize="large" /> </div></label>

                                            <label htmlFor="input-camera"><div className="photoModeIcon"><p className="photoModeIcon-text">{TranslateUI.translate(this.state.language, "camera")}</p><CameraAltOutlinedIcon className='photoModeIcon-single' fontSize="large" /></div></label>
                                        </div>
                                    :
                                    null
                                }
                                {this.state.cameraActive ?
                                    this.state.photoUri.length < 1 ?
                                        <div>
                                            <div style={{ backgroundColor: '#fff', width: '100%', height: '25px' }}></div>
                                            <input type="file" accept="image/*" capture="camera" onChange={() => { this.handleImageUpload() }} className='photo-input-invisible' id="input-camera" name="input-camera" />
                                        </div>
                                        :
                                        <div className="photocontainer">
                                            {TranslateUI.translate(this.state.language, "preview")}
                                            <img className='photopreview' src={this.state.photoUri} />
                                            <button className="upload-button" onClick={() => {
                                                this.setState({ cameraActive: false, addPhotoMode: false, galleryActive: false, photoCentralScreen: false, photoFinished: true })
                                            }}>{TranslateUI.translate(this.state.language, "finish")}</button>

                                        </div>
                                    :
                                    null
                                }
                            </div>
                            :
                            this.state.signatureActive ?
                                <div className='signature'>
                                    <Paper className='goback unselectable' elevation={5} square={true}>
                                        <div className="goback-button" onClick={() => { this.setState({ signatureActive: false }); if (!this.state.signatureFinished) { this.setState({ signatureUri: "" }) } }}>
                                            <ArrowBackIosIcon className="backicon" />
                                            {TranslateUI.translate(this.state.language, "go_back")}
                                        </div>
                                        {this.state.signatureUri.length > 1 ? <div onClick={() => { this.clearCanvas() }}><i className="fas fa-eraser clearicon" /></div> : null}
                                    </Paper>
                                    {this.state.signatureUri.length < 1 ? <p className="signaturehere">{TranslateUI.translate(this.state.language, "signature_here")}</p> : null}
                                    {this.state.signatureFinished ?
                                        <div className="photo-preview-container">
                                            <span className="sigpreview">{TranslateUI.translate(this.state.language, "preview")}</span>
                                            <img className="photopreview-image" src={this.state.signatureUri}></img>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="photocontainer">
                                        <SignatureCanvas ref={(ref) => { this.sigCanvas = ref }}
                                            onEnd={() => { this.handleSignatureStroke() }}
                                            onBegin={() => { this.handleSignatureStroke() }}
                                            maxWidth={1}
                                            minWidth={1}
                                            velocityFilterWeight={1}
                                            throttle={0}
                                            canvasProps={{ height: 300, width: window.innerWidth, className: 'canvas' }}
                                            minDistance={1}
                                            clearOnResize={true}
                                        />

                                        <div className='companyinfo'>
                                            <p>{TranslateUI.translate(this.state.language, "name")}: {this.state.stateObject.object.name}</p>
                                            {this.state.stateObject.object.reg_nr === undefined || this.state.stateObject.object.reg_nr === null || this.state.stateObject.object.reg_nr === '' ?
                                                null
                                                :
                                                <p># {this.state.stateObject.object.reg_nr}</p>
                                            }
                                        </div>
                                    </div>

                                    {this.state.signatureUri.length > 1 && !this.state.signatureFinished ?
                                        <div className="Completestop-button-container-finish unselectable" onClick={() => { this.setState({ signatureActive: false, signatureFinished: true }) }}>{TranslateUI.translate(this.state.language, "finish")}</div>
                                        :
                                        <div className="Completestop-button-container-finish-inactive unselectable" >{TranslateUI.translate(this.state.language, "finish")}</div>
                                    }
                                </div>
                                :
                                <div className='Completestop-screen'>
                                    <Paper className='Completestop-header-container unselectable' elevation={5} square={true}>
                                        <div className='Backbutton-container'>
                                            <BackButton />
                                        </div>
                                        <span className='Completestop-header-text'>{TranslateUI.translate(this.state.language, "complete")} '{this.state.stateObject.object.name}'</span>
                                    </Paper>
                                    <Collapse in={this.state.routeInactiveAlert} classes={{
                                        container: 'stopcard-alert'
                                    }} >
                                        <Alert severity='error'
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({ routeInactiveAlert: false });
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                        >
                                            {TranslateUI.translate(this.state.language, "alert_route_not_started")}
                                        </Alert>
                                    </Collapse>
                                    <Collapse in={this.state.unresolvedStopsAlert} classes={{
                                        container: 'stopcard-alert'
                                    }}>
                                        <Alert severity='error'
                                            action={
                                                <IconButton
                                                    aria-label="close"
                                                    color="inherit"
                                                    size="small"
                                                    onClick={() => {
                                                        this.setState({ unresolvedStopsAlert: false });
                                                    }}
                                                >
                                                    <CloseIcon fontSize="inherit" />
                                                </IconButton>
                                            }
                                        >
                                            {TranslateUI.translate(this.state.language, "alert_stops_not_resolved")}
                                        </Alert>
                                    </Collapse>
                                    <div className='Completestop-content-container'>
                                        <div className='Completestop-detail-container'>
                                            <div className='Completestop-button-upper-container'>
                                                <ClickableContainer css="Completestop-button" path='more'
                                                    stateObject={this.state.stateObject.object}
                                                    routeId={this.state.stateObject.routeId}
                                                    photoUri={this.state.photoUri}
                                                    signatureUri={this.state.signatureUri}
                                                >{TranslateUI.translate(this.state.language, "more_info")}</ClickableContainer>
                                                <div onClick={() => { this.setState({ addPhotoMode: true, photoCentralScreen: true }) }} className="Completestop-button">{TranslateUI.translate(this.state.language, "add_photo")} {this.state.photoUri.length > 1 ? <DoneIcon className='checkmark' /> : null}</div>
                                                <div onClick={() => { this.setState({ signatureActive: true }) }} className="Completestop-button">{TranslateUI.translate(this.state.language, "get_signature")} {this.state.signatureUri.length > 1 ? <DoneIcon className='checkmark' /> : null}</div>
                                                {/* <button className='Completestop-button'>{TranslateUI.translate(this.state.language, "get_signature")}</button> */}
                                            </div>

                                            <div>
                                                <h3>{TranslateUI.translate(this.state.language, "notes")}:</h3>
                                                <TextField
                                                    id='standard-multiline-flexible'
                                                    label={TranslateUI.translate(this.state.language, "enter_here")}
                                                    multiline
                                                    fullWidth
                                                    value={this.state.reasonText}
                                                    onChange={(input) => this.handleInput(input)}
                                                />
                                            </div>
                                            <div className='Completestop-button-container unselectable' onClick={() => this.completeStop()}>
                                                <span>{TranslateUI.translate(this.state.language, "complete_stop")}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    :
                    <div className="Loading-Icon">
                        <Ouroboro color="#3B4252" />
                    </div>
                :
                <div className="Loading-Icon">
                    <Ouroboro color="#3B4252" />
                </div>
        );
    }
}

