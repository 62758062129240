import React, { Component, useState } from 'react';

//Imporing navigation
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
} from "react-router-dom";

import TranslateUI from '../components/Language/Translate.jsx';
import { generatePath } from "react-router";

//Importing styling
import '../App.css';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import ListTwoToneIcon from '@material-ui/icons/ListTwoTone';

//Importing screens
import Routes from '../pages/routes/routes.jsx';
import LeafMap from '../pages/map/map.jsx';
import StopList from '../pages/stoplist/stoplist.jsx';
import MoreInfo from '../pages/moreinfo/moreinfo.jsx';
import SkipStop from '../pages/skip_stop/skip_stop.jsx';
import CompleteStop from '../pages/complete_stop/complete_stop.jsx';
import NotFound from '../pages/not_found/not_found.jsx';


// const useStyles = makeStyles({
//   root: {
//     flexGrow: 1,
//     maxWidth: 500,
//   },
// });

const paperStyle = {
  backgroundColor: "#3B4252",
  zIndex: 999,
  position: "fixed",
  width: "100%"
}


const StyledTab = withStyles(() => ({
  root: {
    color: '#fff',
  },
}))((props) => <Tab {...props} />);



export default class Navigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      token: "",
      routes: [{}],
      selectedRoute: {},
      language: '',
      initialTab: 2,
      context: {},
      defaultId: ''
    }
  }

  getToken = async () => {
    let token = window.location.pathname.split("/");
    this.setState({ token: token[2] }, () => { localStorage.setItem('token', this.state.token) })
  }

  getRouteId = () => {
    let route = window.location.pathname.split("/");
    this.setState({ routeId: route[3] }, () => { localStorage.setItem('routeId', this.state.routeId) })
  }

  // Matches tab to link path
  getTabValue = () => {
    switch (window.location.pathname.split("/")[4]) {
      case "routes":
        return 0;

      case "map":
        return 1;

      default:
        return 2;

    }
  }

  componentDidMount() {
    this.mounted = true;
    this.getToken();
    this.getRouteId();
    this.setState({
      //token: this.props.context.token,
      language: localStorage.getItem('language')
    })
    switch (window.location.pathname.split("/")[4]) {
      case "routes":
        this.setState({ initialTab: 0 });
        break;
      case "map":
        this.setState({ initialTab: 1 });
        break;
      default:
        this.setState({ initialTab: 2 });
        break;
    }

  }


  // Handles tab changing on inputs
  handleChange = (event, newValue) => {
    this.setState({ initialTab: newValue });
  };

  static getDerivedStateFromProps(props, state) {
    if (props.context.routes !== state.routes || props.context.selectedRoute !== state.selectedRoute) 
    {
      // if (props.context.defaultId !== undefined && props.context.defaultId !== "") {
      //   return {
      //     defaultId: props.context.defaultId,
      //   }
      // } else {
      //   return null;
      // }
      return {routes: props.context.routes, selectedRoute: props.context.selectedRoute}
    }
    return null;
    
  }

  render() {
    return (
      this.mounted ?
        <Router>
          {window.location.pathname.split("/")[3] === "" || window.location.pathname.split("/")[3] === undefined || window.location.pathname.split("/")[1] === "not_found" ?
            <Route><NotFound></NotFound></Route>
            :
            <Paper square style={paperStyle} >
              <Tabs
                value={this.getTabValue()}
                onChange={this.handleChange}
                variant="fullWidth"
                TabIndicatorProps={{ style: { backgroundColor: '#fff' } }}
                aria-label="icon label tabs example"
                id="navigation-tabs"
              >
                <StyledTab icon={<EventNoteTwoToneIcon />} component={Link}
                  to={generatePath("/driver/:id/:routeId/routes", {
                    id: this.props.context.token,
                    routeId: window.location.pathname.split("/")[3]
                  })} label={TranslateUI.translate(this.state.language, "routes")} />
                <StyledTab icon={<MapOutlinedIcon />} component={Link}
                  to={generatePath("/driver/:id/:routeId/map", {
                    id: this.props.context.token,
                    routeId: window.location.pathname.split("/")[3]
                  })} label={TranslateUI.translate(this.state.language, "map")} />
                <StyledTab icon={<ListTwoToneIcon />} component={Link}
                  to={generatePath("/driver/:id/:routeId/", {
                    id: this.props.context.token,
                    routeId: window.location.pathname.split("/")[3]
                  })} label={TranslateUI.translate(this.state.language, "stop_list")} />
              </Tabs>
            </Paper>
          }
          <Switch>
            <Route path="/driver/:id/:routeId/map" exact component={LeafMap} >
              <LeafMap context={this.props.context} change={this.props.changeRoute} />
            </Route>
            <Route path="/driver/:id/:routeId/" exact component={StopList}>
              <StopList context={this.props.context} change={this.props.changeRoute} />
            </Route>
            <Route path="/driver/:id/:routeId/routes" exact component={Routes}>
              <Routes context={this.props.context} change={this.props.changeRoute} />
            </Route>
            <Route path="/driver/:id/:routeId/more/:stop" render={(props) => <MoreInfo {...props} update={this.props.changeRoute} />} >
            </Route>
            <Route path="/driver/:id/:routeId/skip/:stop" onChange={this.props.changeRoute} render={(props) => <SkipStop {...props} route={this.state.selectedRoute} update={this.props.changeRoute} />}>
            </Route>
            <Route path="/driver/:id/:routeId/complete/:stop" name={this.state.name} onChange={this.props.changeRoute} render={(props) => <CompleteStop {...props} route={this.state.selectedRoute} update={this.props.changeRoute} />}>
            </Route>
          </Switch>

        </Router >
        :
        <p>Loading...</p>
    )
  }
}