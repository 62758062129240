import React from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import styled from 'styled-components';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import { withStyles } from '@material-ui/core/styles';

// Custom map that generates routes and markers from props

const Wrapper = styled.div`
    width: ${props => props.width};
    height: ${props => props.height};
`;

export default class CustomMap extends React.Component {

    componentDidMount() {
        this.mounted = true;
        this.map = L.map('map', {
            center: [(this.props.initialCoordinates[0]-0.01), this.props.initialCoordinates[1]],
            zoom: 13,
            attributionControl: false
        })

        L.tileLayer('https://tiles.trackpro.lv/cdn/styles/klokantech-basic/{z}/{x}/{y}.png', {

            detectRetina: true
        }).addTo(this.map);

        const markers = this.props.generateMarkers()
        markers.map(item => { L.marker([item._latlng.lat, item._latlng.lng], item.options).addTo(this.map) })

        let polyline = require('@mapbox/polyline');
        let paths = polyline.decode(this.props.geometry);
        let path = L.polyline(paths, {
            color: "#3B4252",
            opacity: 0.4,
            weight: 3
        });
        path.addTo(this.map);

        //Javascript
        const startClientHeight = window.innerHeight;
        if(document.getElementById("map") !== null) {
            document.getElementById("map").style.height = startClientHeight-120+"px";
            document.getElementById("map").style.minHeight = startClientHeight-120+"px";
            window.addEventListener("resize", this.mapHeight, false);
        }
        
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.mapHeight, false);
    }

    //Fix height
    mapHeight = () => {
        const clientHeight = window.innerHeight;
        document.getElementById("map").style.height = clientHeight - 120+"px";
        document.getElementById("map").style.minHeight = clientHeight-120+"px";
    };

    componentDidUpdate() {
        const markers = this.props.generateMarkers()
        markers.map(item => { L.marker([item._latlng.lat, item._latlng.lng], item.options).addTo(this.map) })
        this.map.setView([(this.props.location[0]-(0.05/(this.map._zoom*1.8))), this.props.location[1]]);
    }

    centerFocus = () => {
        this.map.setView([(this.props.location[0]-(0.05/(this.map._zoom*1.8))), this.props.location[1]]);
    }

    render() {
        const StyledLocationIcon = withStyles(() => ({
            root: {
                color: '#3B4252',
                fontSize: 'xxx-large'
            },
        }))((props) => <MyLocationIcon {...props} />);

        return <Wrapper id='map'>
            <StyledLocationIcon className='Map-mylocation' onClick={() => this.centerFocus()} />
        </Wrapper>


    }
}