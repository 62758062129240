import React, { Component } from 'react';
import './moreinfo_styles.css'

import Paper from '@material-ui/core/Paper';
import CallIcon from '@material-ui/icons/Call';
import SmsIcon from '@material-ui/icons/Sms';

import BackButton from '../../components/backbutton/backbutton.jsx';
import TranslateUI from '../../components/Language/Translate.jsx';
import Call from "../../components/phone_communications/call.jsx"
import SendSMS from "../../components/phone_communications/send_sms.jsx"

import { Ouroboro } from 'react-spinners-css';

export default class MoreInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      language: '',
      token: '',
      stateObject: {},
      isLoaded: false,
    }
  }
  async componentDidMount() {
    this.mounted = true;
    this.setState({
      language: localStorage.getItem('language'),
      token: localStorage.getItem('token')
    })

    if (this.props.location.stateObject !== undefined) {
      this.setState({
        stateObject: this.props.location.stateObject
      }, () => { this.setState({ isLoaded: true }) })
    } else {

      await this.getStopItem();

    }
  }

  getLanguage = () => {
    let browserLang = navigator.language || navigator.userLanguage;
    let userLang = browserLang.substring(0, 2);
    switch (userLang) {
      case 'en':
        this.setState({ language: 'en' });
        localStorage.setItem('language', userLang);
        break;
      case 'lv':
        this.setState({ language: 'lv' });
        localStorage.setItem('language', userLang);
        break;
      default:
        this.setState({ language: 'en' });
        localStorage.setItem('language', 'en');
        break;
    }
  }

  endpoint() {
    if (window.location.host === 'dispatch.routistic.com') {
      return 'https://routistic.com/api/v1/';
    }
    return 'https://dev.routistic.com/api/v1/';
  }

  getDomain = () => {
    if (window.location.host.split(":")[0] === 'localhost') {
      return `http://${window.location.host}`
    } else {
      return `https://${window.location.host}`
    }
  }

  getStopItem = async () => {
    await fetch(this.endpoint() + "tasks/view/" + window.location.pathname.split("/")[3], {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + window.location.pathname.split("/")[2],
      }

    }).then(response => response.json()
    ).then((response) => {
      if (response.msg === "success") {
        let currentObj = response.stops.filter(stop => stop.id == window.location.pathname.split("/")[5])[0]
        if (currentObj !== undefined) {
          this.setState({ stateObject: { object: currentObj }, isLoaded: true }, () => { localStorage.setItem('selectedRouteId', window.location.pathname.split("/")[3]) })

        } else {
          window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"

        }
      } else {
        window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
      }
    })

  }

  getToken = async () => {
    let token = window.location.pathname.split("/");

    this.setState({ token: token[2] }, () => { localStorage.setItem('token', this.state.token) })

  }

  getRoutes = async () => {
    await fetch(this.endpoint() + "tasks/list", {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + this.state.token,
      }

    }).then(response => response.json()
    ).then(async (response) => {
      if (response.status !== false) {
        let storedRouteId = localStorage.getItem('selectedRouteId')
        this.setState({ routes: response.tasks }, () => { this.fetchRoute(this.state.routes[0].id) })
      } else {
        window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"
      }
    })
  }

  fetchRoute = async (id) => {
    if (id === null || id === undefined) {
      this.getRoutes();
    } else {
      await fetch(this.endpoint() + "tasks/view/" + id, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer ' + this.state.token,
        }

      }).then(response => response.json()
      ).then((response) => {
        if (response.status !== false) {
          this.setState({ selectedRoute: { task: response.task, stops: response.stops } }, () => { localStorage.setItem('selectedRouteId', id) })
        } else {

          window.location.href = this.getDomain() + "/driver/" + window.location.pathname.split("/")[2] + "/"

        }
      })
    }
  }

  checkIfNotEmpty = (data) => {
    if (data === undefined || data === null || data === "" || data === " ") {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      this.mounted ?
        this.state.isLoaded ?
          <div>
            <div className='Moreinfo-screen'>
              <Paper className='Moreinfo-header-container unselectable' elevation={5} square={true}>
                <div className='Backbutton-container'>
                  <BackButton photoUri={this.props.photoUri} signatureUri={this.props.signatureUri} />
                </div>
                <span className='Moreinfo-header-text'>{TranslateUI.translate(this.state.language, "more_info")}</span>
              </Paper>
              <div className='Moreinfo-content-container'>
                <span className='Moreinfo-title'>{this.state.stateObject.object.name}</span>
                <div className='Moreinfo-detail-container'>

                  {this.checkIfNotEmpty(this.state.stateObject.object.arrive_time) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "time")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.arrive_time}
                      </span>
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.address) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "address")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.address}
                      </span>
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.code) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "code")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.code}
                      </span>
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.reg_nr) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "reg_nr")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.reg_nr}
                      </span>
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.phone) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "phone")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.phone}
                      </span>
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.work_start) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "time_window")}
                      </span>
                      {this.state.stateObject.object.work_start !== undefined && this.state.stateObject.object.work_start !== this.state.stateObject.object.work_end?
                        <span className='Moreinfo-detail-content'>{this.state.stateObject.object.work_start} - {this.state.stateObject.object.work_end}</span>
                        :
                        <span className='Moreinfo-detail-content'>{TranslateUI.translate(this.state.language, "no_time_window")}</span>
                      }
                    </div>
                    :
                    null
                  }
                  {this.checkIfNotEmpty(this.state.stateObject.object.comment) ?
                    <div className='Moreinfo-detail-row'>
                      <span className='Moreinfo-detail-title'>
                        {TranslateUI.translate(this.state.language, "comment")}
                      </span>
                      <span className='Moreinfo-detail-content'>
                        {this.state.stateObject.object.comment}
                      </span>
                    </div>
                    :
                    null
                  }
                </div>
              </div>
            </div>
            <div className='Moreinfo-communications-buttons'>
              <Call number={this.state.stateObject.object.phone}>
                <CallIcon className="Moreinfo-icon" />
              </Call>
              <SendSMS number={this.state.stateObject.object.phone}>
                <SmsIcon className="Moreinfo-icon" />
              </SendSMS>
            </div>
          </div>
          :
          <div className="Loading-Icon">
            <Ouroboro color="#3B4252" />
          </div>
        :
        <div className="Loading-Icon">
          <Ouroboro color="#3B4252" />
        </div>
    );
  }

}

