import React, { Component } from 'react';
import './communication_styles.css'
import TranslateUI from '../Language/Translate.jsx';

import {
    deviceDetect,
} from "react-device-detect";

const device = deviceDetect();

export default class SendSms extends Component {
    constructor(props) {
        super(props)

        this.state = {
            device: device,
            language: localStorage.getItem('language'),
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    render() {
        return (
        <a className='Moreinfo-button' href={`sms:${this.props.number};?&body=`}>{this.props.children}{TranslateUI.translate(this.state.language, "send_sms")}</a>
        )
    }
}
