import React, { Component } from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
} from "react-router-dom";
import { generatePath } from "react-router";

// Wrapper to navigate to cards and pass props

export default class ClickableContainer extends Component {
    constructor(props) {
        super(props)

        let pathToken = window.location.pathname.split("/");

        this.state = {
            language: 'en',
            token: pathToken[2]
        }
    }

    componentDidMount() {
        this.mouted = true; 

        // Set token from link
        let splitToken = window.location.pathname.split("/");
        this.setState({token: splitToken[2]})
    }
    

    

    render() {
        return (
            this.mouted ? 
            <Link to={{
                pathname: this.props.path === '/' ? generatePath(`/driver/:id/:routeId`, {
                    id: this.state.token,
                    path: this.props.path,
                    routeId: this.props.newRouteId
                  }) 
                : generatePath(`/driver/:id/:routeId/:path/:stop`, {
                    id: this.state.token,
                    path: this.props.path,
                    stop: this.props.stateObject.id,
                    routeId: window.location.pathname.split("/")[3]
                  }),
                stateObject:
                {
                    object: this.props.stateObject,
                    routeId: this.props.context !== undefined ? this.props.context.selectedRoute.task.id : null,
                    token: this.props.context !== undefined ? this.props.context.token : null,
                    finish: this.props.finish,
                    change: this.props.change !== undefined ? this.props.change : null,
                    photoUri: this.props.photoUri !== undefined && this.props.photoUri !== '' ? this.props.photoUri : null,
                    signatureUri: this.props.signatureUri !== undefined && this.props.signatureUri !== '' ? this.props.signatureUri : null,
                    ...this.props
                }
            }}
                onClick={this.props.change !== undefined ? () => { this.props.change(this.props.newRouteId) } : null}
                
                className={`${this.props.css} unselectable`}
                style={{ textDecoration: 'none', color: 'inherit', zIndex: 20 }}>
                {this.props.children}
            </Link>
            :
            null
        )
    }
}