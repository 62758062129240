import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';

import './map_styles.css';

import L from 'leaflet';

import CustomMap from '../../components/map/map.jsx';
import MapCard from '../../components/mapcard/mapcard.jsx';
import MapCardFocused from '../../components/mapcard/mapcard_focused';
import MapCardFinished from '../../components/mapcard/mapcard_finished';
import TranslateUI from '../../components/Language/Translate.jsx';

import { Swiper, Navigation, Pagination } from 'swiper/js/swiper.esm';
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';

import { Ouroboro } from 'react-spinners-css';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


export default class Map extends Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();

    this.state = {
      recievedProps: false,
      testRoute: [{}],
      location: [0, 0],
      language: localStorage.getItem('language'),
      routes: [{}],
      currentPosition: 0,
      routeInactiveAlert: false,
      unresolvedStopsAlert: false,
      routeActive: null,
      allStopsComplete: false
    }
  }


  componentDidMount() {
    const me = this;
    this.mounted = true;

    // Checks if props are recieved and only then loads the data, if not loaded then loading displayed.
    // Functions inside append driver starting and ending cards to the array of routes. Also the focus property gets added to the objects here.
    // if (this.props.context.selectedRoute.stops !== undefined) {
    //   let routesWithDriverState = []
    //   routesWithDriverState = [...this.props.context.selectedRoute.stops];
    //   if (!routesWithDriverState.some((item) => item.id === 0)) {
    //     routesWithDriverState.unshift({
    //       name: this.props.context.selectedRoute.transport.name,
    //       lat: this.props.context.selectedRoute.transport.base_lat,
    //       lng: this.props.context.selectedRoute.transport.base_lng,
    //       address: this.props.context.selectedRoute.transport.base_address,
    //       arrive_time: this.props.context.selectedRoute.transport.start_time,
    //       id: 0,
    //       task_status: "driver"
    //     })
    //   }
    //   if (!routesWithDriverState.some((item) => item.id === 1)) {
    //     routesWithDriverState.push({
    //       name: this.props.context.selectedRoute.transport.name,
    //       lat: this.props.context.selectedRoute.transport.return_lat,
    //       lng: this.props.context.selectedRoute.transport.return_lng,
    //       address: this.props.context.selectedRoute.transport.return_address,
    //       arrive_time: this.props.context.selectedRoute.transport.return_time,
    //       id: 1,
    //       task_status: "driver"
    //     })
    //   }
    //   let currentPosCard = this.state.routes.findIndex((route, index) => route.task_status === null);
    //   let currentPosLoc = this.state.routes.findIndex((route, index) => route.task_status === null);
    //   if (!this.state.hasStarted) {
    //     currentPosCard = 0;
    //     currentPosLoc = 0;
    //   } else {
    //     if (currentPosCard === -1) {
    //       if (this.state.routes[this.state.routes.length - 1].lat !== 0) {
    //         currentPosCard = this.state.routes.length - 1;
    //         currentPosLoc = this.state.routes.length - 1;
    //       } else {
    //         currentPosCard = this.state.routes.length - 1;
    //         currentPosLoc = this.state.routes.length - 2;
    //       }
    //     }
    //   }
    //   this.setState({
    //     location: [routesWithDriverState[currentPosLoc].lat, routesWithDriverState[currentPosLoc].lng],
    //     hasStarted: this.props.context.selectedRoute.task.status !== null ? true : false,
    //     isActive: this.props.context.selectedRoute.task.status !== 'finished' ? true : false,
    //     routes: routesWithDriverState,
    //     currentPosition: currentPosCard,
    //     language: localStorage.getItem('language')
    //   }, () => {
    //     this.setState(prevState =>
    //       prevState.routes.map((item, index) => index === 0 ? { ...item, focus: true } : { ...item, focus: false })
    //       , () => {

    //         this.setState({
    //           recievedProps: true,
    //           //currentPosition: this.state.routes.findIndex(route => route.task_status === null),

    //           routeActive: this.props.context.selectedRoute.task.status !== null ? true : false,
    //           allStopsComplete: !this.state.routes.some(item => item.task_status === null)
    //         })
    //       })
    //   })
    //this.updateFocus();


  }

  // this.swiperParams = {
  //   Swiper,
  //   modules: [Navigation, Pagination],
  //   pagination: {
  //     el: '.swiper-pagination',
  //     clickable: true
  //   },
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   },
  //   slidesPerView: 2,
  //   spaceBetween: 210,
  //   centeredSlides: true,
  //   containerClass: 'customized-swiper-container',
  //   // touchReleaseOnEdges: true,
  //   // touchMoveStopPropagation: true,
  //   // touchRation: 0.5,
  //   shouldSwiperUpdate: true,
  //   activeSlideKey: `card_${me.state.currentPosition}`,
  //   //getSwiper: this.getSwiperInstance,
  //   on: {
  //     slideNextTransitionEnd: function () {
  //       const currentPosition = this.activeIndex;
  //       if (me.state.routes[currentPosition].lat !== 0) {
  //         //me.setState({  });
  //         me.setState({
  //           routes: me.state.routes.map((route, index) => {
  //             if (index === currentPosition) {
  //               //this.activeIndex = index; 
  //               return ({ ...route, focus: true })
  //             }
  //             else {
  //               return ({ ...route, focus: false })
  //             }
  //           }),
  //           currentPosition: currentPosition,
  //           location: [me.state.routes[currentPosition].lat, me.state.routes[currentPosition].lng]
  //         })

  //       } else {
  //         me.setState(prevState => prevState.routes.map((route) => { route.focus = false }));
  //       }

  //     },
  //     slidePrevTransitionEnd: function () {
  //       let currentPosition = this.activeIndex;
  //       let t = me.state.routes[currentPosition]
  //       if (me.state.routes[currentPosition].lat !== 0) {
  //         me.setState({ location: [me.state.routes[currentPosition].lat, me.state.routes[currentPosition].lng] });
  //         me.setState(prevState => prevState.routes.map((route, index) => { if (index === currentPosition) { route.focus = true } else { route.focus = false } }));
  //         me.setState({ currentPosition: currentPosition });
  //         me.buildMarker();
  //       } else {
  //         me.setState(prevState => prevState.routes.map((route) => { route.focus = false }));
  //       }
  //     },
  //   }
  // }


  // Removes some redundant re-renders
  shouldComponentUpdate(nextProps, prevState) {
    if (nextProps !== this.props) {
      return true;
    } else if (prevState.location !== this.state.location) {
      return true;
    } else if (this.state !== prevState) {
      return true;
    } else if (prevState.hasStarted !== this.state.hasStarted) {
      return true;
    } else {
      return false;
    }

  }


  // Similar logic to what happens on component did mount 
  static getDerivedStateFromProps(props, state) {
    if (props.context.selectedRoute !== state.selectedRoute && props.context.selectedRoute.stops !== undefined) {
      let routesWithDriver = []
      routesWithDriver = [...props.context.selectedRoute.stops];
      if (!routesWithDriver.some((item) => item.id === 0)) {
        routesWithDriver.unshift({
          name: props.context.selectedRoute.transport.name,
          lat: props.context.selectedRoute.transport.base_lat,
          lng: props.context.selectedRoute.transport.base_lng,
          address: props.context.selectedRoute.transport.base_address,
          arrive_time: props.context.selectedRoute.transport.start_time,
          id: 0,
          task_status: "driver"
        })
      }
      if (!routesWithDriver.some((item) => item.id === 1)) {
        if (props.context.selectedRoute.transport.return_lat !== 0) {
          routesWithDriver.push({
            name: props.context.selectedRoute.transport.name,
            lat: props.context.selectedRoute.transport.return_lat,
            lng: props.context.selectedRoute.transport.return_lng,
            address: props.context.selectedRoute.transport.return_address,
            arrive_time: props.context.selectedRoute.transport.return_time,
            id: 1,
            task_status: "driver"
          })
        }
      }
      const passRoutes = routesWithDriver.map((item, index) => index === 0 ? { ...item, focus: true } : { ...item, focus: false })
      if (passRoutes.length > 0) {
        let currentPosCard = passRoutes.findIndex((route, index) => route.task_status === null);
        let currentPosLoc = passRoutes.findIndex((route, index) => route.task_status === null);
        if (props.context.selectedRoute.task.status !== 'started') {
          currentPosCard = 0;
          currentPosLoc = 0;
        } else {
          if (currentPosCard === -1) {
            if (passRoutes[passRoutes.length - 1].lat !== 0) {
              currentPosCard = passRoutes.length - 1;
              currentPosLoc = passRoutes.length - 1;
            } else {
              currentPosCard = passRoutes.length - 1;
              currentPosLoc = passRoutes.length - 2;
            }
          }
        }
        return {
          selectedRoute: props.context.selectedRoute,
          routes: passRoutes,
          token: props.context.token,
          driver: props.context.driver,
          hasStarted: props.context.selectedRoute.task.status !== null ? true : false,
          isActive: props.context.selectedRoute.task.status !== 'finished' ? true : false,
          recievedProps: true,
          location: [passRoutes[currentPosLoc].lat, passRoutes[currentPosLoc].lng],
          currentPosition: currentPosCard
        }
      }
      this.state.updateFocus();

    } else {
      return null;
    }
  }


  endpoint() {
    if (window.location.host === 'dispatch.routistic.com') {
      return 'https://routistic.com/api/v1/';
    }
    return 'https://dev.routistic.com/api/v1/';
  }

  changeRouteStatus = async (status) => {
    // routeActive: props.context.selectedRoute.task.status !== null ? true : false,
    //       allStopsComplete: passRoutes.some(item => item.task_status === null)
    //if (status === 'finished' && this.state.routes.some(item => item.task_status === null))
    if (this.state.hasStarted === false && status === 'finished') {
      this.setState({ routeInactiveAlert: true })
    } else if (this.state.routes.some(item => item.task_status === null) && status === 'finished') {
      this.setState({ unresolvedStopsAlert: true })
    } else {
      await fetch(`${this.endpoint()}tasks/view/${this.props.context.selectedRoute.task.id}/status`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          "Content-Type": 'application/json',
          Authorization: 'Bearer ' + this.props.context.token,
        },
        body: JSON.stringify({
          "status": status
        })
      }).then(response => response.json()
      ).then((response) => {
        if (response !== false) {
          if (status === 'started') {
            this.setState({ isActive: true, hasStarted: true }, () => { this.props.change(this.props.context.selectedRoute.task.id); this.forceUpdate() });
           
          } else if (status === 'finished') {
            this.setState({ isActive: false }, () => { this.props.change(this.props.context.selectedRoute.task.id) })
          }
        } else {
          console.log('ERROR!', response);
        }
      })
    }
  }

  getSwiperInstance = (swiper) => {
    this.setState({ swiper: swiper });
  }

  updateFocus = () => {
    let focusedIndex = this.state.routes.findIndex((route, index) => route.task_status !== "problem" && route.task_status !== "completed" ? index : 0);
    let focusedItem = this.state.routes.find((route, index) => route.task_status !== "problem" && route.task_status !== "completed" ? route : 0);
    this.state.swiper.activeIndex = focusedIndex;
    this.setState({ currentPosition: focusedIndex });
    this.setState({ location: [focusedItem.lat, focusedItem.lng] });
  }


  //Build markers
  buildMarker = () => {
    if (this.state.recievedProps) {
      let markers = this.state.routes
      let markerOutput = [];
      markerOutput = markers.map((marker, index) => {
        if (marker.lat === 0) {
          return;

          //return <Marker icon={customMarker} key={'marker_' + marker.id} position={[marker.lat, marker.lng]}></Marker>
        } else {
          let fancyMarker = L.divIcon({
            className: 'fancy-icon',
            // this.state.routes[index].focus ? 'fancy-icon-active' :
            html: ReactDOMServer.renderToString(
              <div className='fancy-icon-inner-container'>
                {this.state.routes[index].focus ? 
                  <i className="fas fa-map-marker fa-3x fancy-icon-inner-icon-active"></i>
                  :
                  <i className="fas fa-map-marker fa-3x fancy-icon-inner-icon"></i>
                }
                <span className="fancy-icon-number">{index + 1}</span>
              </div>
            )
          })
          return L.marker([marker.lat, marker.lng], { icon: fancyMarker });
          
        }
      })

      return markerOutput.filter(x => x !== undefined);
    }
  }


  render() {
    if (this.state.recievedProps === true) {
      if (this.state.selectedRoute.stops.length === 0) {
        return (<span className='no-valid-stops'>No valid stops</span>)
      }
      const me = this;
      const swiperParams = {
        Swiper,
        modules: [Navigation, Pagination],
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        slidesPerView: 2,
        spaceBetween: 200,
        centeredSlides: true,
        containerClass: 'customized-swiper-container',
        //touchReleaseOnEdges: true,
        //touchMoveStopPropagation: true,
        touchRation: 0.5,
        //shouldSwiperUpdate: true,
        //this.state.routes.findIndex((route, index) => {if (route.focus === true) {console.log(index, route); return index} else {console.log(index,route)})}
        activeSlideKey: `card_${me.state.currentPosition}`,
        //rebuildOnUpdate: true,
        //getSwiper: this.getSwiperInstance,
        //runCallbacksOnInit: true,
        on: {
          slideNextTransitionEnd: function () {
            const currentPosition = this.activeIndex;
            if (me.state.routes[currentPosition].lat !== 0) {
              // me.setState({  });
              me.setState({
                routes: me.state.routes.map((route, index) => {
                  if (index === currentPosition) {
                    //this.activeIndex = index; 
                    return ({ ...route, focus: true })
                  }
                  else {
                    return ({ ...route, focus: false })
                  }
                }),
                currentPosition: currentPosition,
                location: [me.state.routes[currentPosition].lat, me.state.routes[currentPosition].lng]
              })
            } else {
              me.setState(prevState => prevState.routes.map((route) => { route.focus = false }));
              me.setState({ currentPosition: currentPosition })
            }

          },
          slidePrevTransitionEnd: function () {
            const currentPosition = this.activeIndex;
            if (me.state.routes[currentPosition].lat !== 0) {
              me.setState({
                routes: me.state.routes.map((route, index) => {
                  if (index === currentPosition) {
                    return ({ ...route, focus: true })
                  }
                  else {
                    return ({ ...route, focus: false })
                  }
                }),
                currentPosition: currentPosition,
                location: [me.state.routes[currentPosition].lat, me.state.routes[currentPosition].lng]
              })
            } else {
              me.setState(prevState => prevState.routes.map((route) => { route.focus = false }));
              me.setState({ currentPosition: currentPosition })
            }
          },
          init: function (swiper) {
            this.swiper = swiper
          }
        }
      }
      return (
        <div>
          <div className='Stoplist-header-container unselectable'>
            <div className='Stoplist-leftside-header'>
              <LocationOnIcon fontSize='small' />
              <span className='Stoplist-header-text'>{this.props.context.selectedRoute.stops.length} {TranslateUI.translate(this.props.language, "stops")}</span>
            </div>
            <div style={{ marginRight: 35 }}>
              <span className='Stoplist-header-text'>{this.props.context.selectedRoute.task.name}</span>
            </div>
          </div>

          <div className='Map-screen'>
            <Collapse in={this.state.routeInactiveAlert} classes={{
              container: 'map-alert'
            }}>
              <Alert severity='error'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ routeInactiveAlert: false });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {TranslateUI.translate(this.state.language, "alert_route_not_started")}
              </Alert>
            </Collapse>
            <Collapse in={this.state.unresolvedStopsAlert} classes={{
              container: 'map-alert'
            }}>
              <Alert severity='error'
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      this.setState({ unresolvedStopsAlert: false });
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {TranslateUI.translate(this.state.language, "alert_stops_not_resolved")}
              </Alert>
            </Collapse>
            <CustomMap initialCoordinates={this.state.location} geometry={this.props.context.selectedRoute.transport.geometry} generateMarkers={() => this.buildMarker()} location={this.state.location} />
            <ReactIdSwiperCustom {...swiperParams} touchRatio={0.5} touchMoveStopPropagation={true} wrapperClass='Mapcard_container' containerClass='Map-swiper-container'>
              {this.state.isActive ?
                this.state.routes.map((item, index) => {
                  if (item.task_status !== null && item.task_status !== undefined && item.task_status !== "driver" || item.task_status === "driver" && !((this.state.routes.length - 1) === index) && this.state.hasStarted && item.address !== null) {

                    return (

                      <div key={'card_' + index}><MapCardFinished
                        id={item.id}
                        route={item}
                        isLastItem={false}
                        updateFocus={this.updateFocus}
                        {...this.props} /></div>
                    )
                  } else if (index !== 0 && this.state.routes[index - 1].task_status !== null && this.state.hasStarted || !this.state.hasStarted && index === 0) {
                    return (
                      <div key={'card_' + index}><MapCardFocused
                        route={item}
                        id={item.id}
                        isLastItem={this.state.routes.length - 1 === index ? true : false}
                        routeActive={this.state.isActive}
                        hasStarted={this.state.hasStarted}
                        changeStatus={this.changeRouteStatus}
                        updateFocus={this.updateFocus}
                        {...this.props} /></div>
                    )
                  } else {
                    return (
                      <div key={'card_' + index}><MapCard
                        route={item}
                        id={item.id}
                        updateFocus={this.updateFocus}
                        isLastItem={this.state.routes.length - 1 === index ? true : false}
                        routeActive={this.state.isActive}
                        {...this.props} /></div>
                    )
                  }
                })
                :
                this.state.routes.map((item, index) => {
                  return (
                    <div key={'card_' + index}><MapCardFinished
                      route={item}
                      id={item.id}
                      updateFocus={this.updateFocus}
                      isLastItem={false}
                      {...this.props} /></div>
                  )
                })
              }
            </ReactIdSwiperCustom>
          </div>
        </div>
      )
    } else {
      return <div className="Loading-Icon">
        <Ouroboro color="#3B4252" />
      </div>
    }
  }
}