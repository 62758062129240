import React, { Component } from 'react';
import './communication_styles.css'
import TranslateUI from '../Language/Translate.jsx';

import {
    deviceDetect,
    isMobile
} from "react-device-detect";

const device = deviceDetect();

export default class Call extends Component {
    constructor(props) {
        super(props)

        this.state = {
            device: device,
            language: localStorage.getItem('language'),
        }
    }

    componentDidMount() {
        this.mounted = true;
    }

    render() {
            return (
            <a className='Moreinfo-button' href={`tel:${this.props.number}`}>{this.props.children}{TranslateUI.translate(this.state.language, "call")}</a>
            )
    }
}
