import React, {Component} from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';


import {
    BrowserRouter as Router,
    useHistory
} from "react-router-dom";

export default function BackButton({ children }, props) {
    let history = useHistory()
    
    return (
      <ArrowBackIosIcon fontSize='default' onClick={() => {
      if (window.location.pathname.split("/")[4] !== 'more'){
        localStorage.removeItem('photo_uri');
        localStorage.removeItem('signature_uri'); 
      } 
      
      history.goBack()}}
      >
        {children}
      </ArrowBackIosIcon>
    )
}