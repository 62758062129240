import React, { Component, useContext } from 'react';


import './not_found_styles.css';



export default class NotFound extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="not-found-container">
          <span>DRIVER NOT FOUND...</span>
      </div>
    )
  }
}
